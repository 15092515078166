import Image from 'next/image'

export default function Header() {
  return (
    <nav className="cgt-navbar--light">
      <div className="cgt-navbar__container">
        <span className="cgt-ml-10">
          <Image src="/logo.svg" alt="Racoon" height="50" width="160" />
        </span>
      </div>
    </nav>
  )
}
